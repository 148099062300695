.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  font-size: 1.2rem;
  gap: 5px;
}

.pagination .page-num {
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
}
.pagination .page-num:hover {
  background-color: #7139d4;
  color: #fff;
}

.pagination .active {
  background-color: #7139d4;
  color: #fff;
}

.explainer-video-bg {
  background: radial-gradient(
    circle at 100%,
    rgba(255, 255, 255, 0.12),
    rgba(255, 255, 255, 0)
  );
}
