/* @font-face {
  font-family: Inter;
  src: url("../public/assets/fonts/Inter-Bold.ttf");
} */
.interB {
  font-family: Inter;
  font-weight: 700;
}
/* Medium (500) Weight */
@font-face {
  font-family: Inter;
  src: url("../public/assets/fonts/Inter-Medium.ttf");
  font-weight: 500;
}

/* Medium (600) Weight */
@font-face {
  font-family: Inter;
  src: url("../public/assets/fonts/Inter-SemiBold.ttf");
  font-weight: 600;
}

/* Bold Weight */
@font-face {
  font-family: Inter;
  src: url("../public/assets/fonts/Inter-Bold.ttf");
  font-weight: 700;
}

body {
  margin: 0;
  font-family: Inter;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

h1,
h2,
h3,
p,
h5,
h4,
button,
a {
  font-family: Inter !important;
}

.accordion {
  margin-top: 2rem;
  border: none;
  border-width: 0px;
  font-family: Inter !important;
}

.accordionItem {
  background: white;
  border: 0.8px solid rgba(128, 128, 128, 0.143);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  font-family: Inter !important;
}
.accordionItem.expamded {
  box-shadow: var(--shadow);
  border-radius: 6px;
  font-family: Inter !important;
}
.accordionButton {
  background: white;
  padding: 1rem;
  width: 100%;
  cursor: pointer;
  font-family: Inter !important;
}
.chakra-accordion__panel {
  font-family: Inter !important;
}
.mode:hover {
  scale: 1.1;
  box-shadow: var(--shadow);
  cursor: pointer;
}

.menu-icon {
  display: none;
}

#reviews .swiper-slide-shadow {
  background-color: rgba(245, 248, 250, 1) !important;
  /* display: none !important; */
}

.backdropFlter {
  backdrop-filter: blur(10px);
}

.mediaGradient {
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
}

#mediaPlayerSlider .swiper-slide {
  background-color: transparent !important;
}

.stillQuestionLight {
  filter: blur(30px);
  transform: rotate(-55deg);
  border-radius: 80px;
}

.explainer-iframe {
  aspect-ratio: auto 5/3;
  width: 100%;
}

@media screen and (min-width: 786px) {
  .explainer-iframe {
    height: 400px;
    width: 100%;
  }
}

@media screen and (min-width: 1050px) {
  .stillQuestionLight {
    filter: blur(30px);
    transform: rotate(-35deg);
    border-radius: 80px;
  }

  .explainer-iframe {
    aspect-ratio: auto 5 / 3;
    width: 100%;
  }
}
